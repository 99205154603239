import Input from '@/components/Input/Input.vue';
import Callout from '@/components/Callout/Callout.vue';
import Select from '@/components/Select/Select.vue';
import Button from '@/components/Button/Button.vue';
import Tab from '@/components/Tab/Tab.vue';
import Wysiwyg from '@/components/Wysiwyg/Wysiwyg.vue';
import axios from 'axios';
import jsonToFormData from 'json-form-data';

export default {
  components: {
    Input,
    Callout,
    Select,
    Button,
    Tab,
    Wysiwyg,
  },
  data() {
    return {
      currentTab: 1,
      artists: [],
      categories: [],
      postBody: {},
      errors: [],
      success: '',
      // eslint-disable-next-line no-underscore-dangle
      currentUserRole: window._CURRENT_USER_ROLE,
    };
  },
  computed: {
    isGallery() {
      return this.currentUserRole === 'gallery';
    },
    isArtist() {
      return this.currentUserRole === 'artist';
    },
    showPrev() {
      if (this.isArtist) {
        return this.currentTab > 2;
      }

      return this.currentTab > 1;
    },
  },
  methods: {
    incrementTab() {
      if (this.currentTab < 3) {
        this.currentTab += 1;
      }
    },
    decrementTab() {
      if (this.currentTab > 1) {
        this.currentTab -= 1;
      }
    },
    submitData() {
      axios.post('/wp-json/api/add_artist', jsonToFormData(this.postBody))
        .then((response) => {
          this.success = response.data;
        })
        .catch((error) => {
          this.errors.push(error);
          console.log(error);
        });
    },
    uploadFile(value) {
      this.postBody.thumbnailImage = value;
      console.log(value);
    },
    uploadGallery(value) {
      this.postBody.galleryImages = value;
      console.log(value);
    },
  },
  mounted() {
    if (this.isArtist) {
      this.currentTab = 2;
    }

    axios
      .get('/wp-json/api/artists')
      .then((data) => {
        this.artists = data.data;
      });
    axios
      .get('/wp-json/api/product-categories')
      .then((data) => {
        this.categories = data.data;
      });
  },
};
