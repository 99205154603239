import Vue from 'vue';
import NewProductForm from './modules/NewProductForm/NewProductForm.vue';

Vue.config.productionTip = false;

if (document.getElementById('new-product-wizard')) {
  new Vue({
    render: (h) => h(NewProductForm),
  }).$mount('#new-product-wizard');
}
