export default {
  name: 'Select',
  props: {
    label: String,
    options: Object,
    id: String,
  },
  data: {
    options: [],
    selected: '',
  },
};
