export default {
  name: 'Input',
  props: {
    label: String,
    type: String,
    placeholder: String,
    id: String,
    multiple: Boolean,
  },
  data() {
    return {
      preview: null,
    };
  },
  methods: {
    previewFiles(event) {
      if (this.multiple) {
        this.$emit('file-uploaded', event.target.files);
      } else {
        this.$emit('file-uploaded', event.target.files[0]);
      }

      if (this.multiple && event.target.files && event.target.files.length) {
        this.preview = [...event.target.files].map((file) => URL.createObjectURL(file));
      } else if (event.target.files && event.target.files.length) {
        const file = event.target.files[0];
        this.preview = URL.createObjectURL(file);
      } else {
        this.preview = null;
      }
    },
  },
};
